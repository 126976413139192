import api from '@/api/api'
import { USERS_INFO_API_CONFIG } from '@/api/config'
import { localStorageHandler } from '@docshouse/dh-ui-components'
import { LOCAL_STORAGE_KEYS, determineAppMode } from '@docshouse/dh-ui-types'
import { ActionContext, Commit } from 'vuex'

export type UsersInfoState = Record<string, unknown>
const appMode = determineAppMode(window.location, process.env)
let usersInfoUrl = USERS_INFO_API_CONFIG.BASE_URL_PRODUCTION
if (appMode === 'DEVELOPMENT_IN_SERVICE') {
  usersInfoUrl = USERS_INFO_API_CONFIG.BASE_URL_DEVELOPMENT_IN_SERVICE
} else if (appMode === 'DEVELOPMENT_IN_BASE_APP') {
  usersInfoUrl = USERS_INFO_API_CONFIG.BASE_URL_DEVELOPMENT_IN_BASE_APP
}

export default {
  namespaced: true,

  state: {
    userName: '',
    userInfo: null,
  } as UsersInfoState,

  getters: {
    getUserName: (state: any) => state.userName,
    getUserInfo: (state: any) => state.userInfo,
  },

  mutations: {
    setUserName(state: any, payload: string) {
      state.userName = payload
    },
    setUserInfo(state: any, payload: string) {
      state.userInfo = payload
    },
  },

  actions: {
    async getUserProfiles(ctx: ActionContext<UsersInfoState, any>, payload: any): Promise<unknown> {
      const config = { ...payload, baseURL: usersInfoUrl }
      const response = await api.usersInfo.getUserProfiles(config)
      return response.data
    },

    async getUserInfo({ commit }: { commit: Commit }, payload: any): Promise<unknown> {
      try {
        const config = { ...payload, baseURL: usersInfoUrl }
        const response = await api.usersInfo.getUserInfo(config)
        localStorageHandler('set', false, LOCAL_STORAGE_KEYS.SESSION_DATA, null, response.data)
        commit('setUserInfo', response.data)

        const family = response.data?.activeProfile
          ? response.data.activeProfile?.familyName ?? ''
          : response.data?.familyName ?? ''
        const given = response.data?.activeProfile
          ? response.data.activeProfile?.givenName ?? ''
          : response.data?.givenName ?? ''
        const middle = response.data?.activeProfile
          ? response.data.activeProfile?.middleName ?? ''
          : response.data?.middleName ?? ''
        const userName = family + ' ' + given + ' ' + middle
        commit('setUserName', userName)
        if (response instanceof TypeError) {
          return response
        } else {
          return response.data
        }
      } catch (error) {
        console.log(error)
        return error
      }
    },
  },
}
