import axios, { AxiosError } from 'axios'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { API_CONFIG, GATEWAY_API_CONFIG, USERS_INFO_API_CONFIG } from '@/api/config'
import {
  getAxiosErrorLogEventPayload,
  getAxiosRequestLogEventPayload,
  getAxiosResponseLogEventPayload,
  localStorageHandler,
} from '@docshouse/dh-ui-components'
import { SessionData } from '@/types/auth.types'
import {
  AxiosInstanceExtended,
  DispatchLogEventPayload,
  LOCAL_STORAGE_KEYS,
  determineAppMode,
} from '@docshouse/dh-ui-types'
import { setCookie } from 'typescript-cookie'

const appMode = determineAppMode(window.location, process.env)
let usersInfoUrl = USERS_INFO_API_CONFIG.BASE_URL_PRODUCTION
if (appMode === 'DEVELOPMENT_IN_SERVICE') {
  usersInfoUrl = USERS_INFO_API_CONFIG.BASE_URL_DEVELOPMENT_IN_SERVICE
} else if (appMode === 'DEVELOPMENT_IN_BASE_APP') {
  usersInfoUrl = USERS_INFO_API_CONFIG.BASE_URL_DEVELOPMENT_IN_BASE_APP
}

let gatewayUrl = GATEWAY_API_CONFIG.BASE_URL_PRODUCTION
if (appMode === 'DEVELOPMENT_IN_SERVICE') {
  gatewayUrl = GATEWAY_API_CONFIG.BASE_URL_DEVELOPMENT_IN_SERVICE
} else if (appMode === 'DEVELOPMENT_IN_BASE_APP') {
  gatewayUrl = GATEWAY_API_CONFIG.BASE_URL_DEVELOPMENT_IN_BASE_APP
}

export const defaultConfig = {
  baseURL: process.env.NODE_ENV === 'development' ? API_CONFIG.BASE_URL_DEVELOPMENT : API_CONFIG.BASE_URL_PRODUCTION,
  usersInfoUrl: usersInfoUrl,
  headers: {
    'Content-Type': 'application/json',
  },
}

const axiosInstance = axios.create(defaultConfig) as AxiosInstanceExtended

axiosInstance.interceptors.request.use((config) => {
  createLogEvent(getAxiosRequestLogEventPayload(config))
  return config
})

axiosInstance.interceptors.response.use(
  (response) => {
    createLogEvent(getAxiosResponseLogEventPayload(response))

    return response
  },
  (error: AxiosError) => {
    let message =
      error.response?.data?.error_message ??
      error.response?.data?.message ??
      (typeof error.response?.data === 'string' && error.response?.data)

    if (error.response?.status === 401) {
      console.trace('Axios', error)
      message = '' // i18n.t('notifications.authError')
      setAuthorization(error)
      return
      //@ts-ignore
      // error = null
      // store.dispatch('auth/logout')
    } else if (error.response?.status === 403) {
      message = i18n.t('common.notifications.accessDeniedError')
    } else if (error.response?.status === 500) {
      message = message ? message : i18n.t('notifications.serverError')
    } else {
      message = message ? message : i18n.t('notifications.unknownError')
    }

    error.message = message

    createLogEvent(getAxiosErrorLogEventPayload(error))

    throw error
  }
)

function setAuthorization(error: AxiosError) {
  if (error?.response?.headers?.location) {
    if (process.env.NODE_ENV === 'development') {
      setCookie(
        'X-Ldm-Develop-BaseUrl',
        `http://${process.env.VUE_APP_PROXY_HOST}:${process.env.VUE_APP_PROXY_PORT}/proxy`
      )
      window.location.href =
        '/proxy' + error?.response?.headers?.location + `?successAuthUri=${encodeURIComponent(window.location.href)}`
    } else {
      window.location.href =
        error?.response?.headers?.location + `?successAuthUri=${encodeURIComponent(window.location.href)}`
    }
  }
}

function createLogEvent(payload: DispatchLogEventPayload) {
  store.dispatch('logger/createLogEvent', {
    payload,
  })
}

export default axiosInstance
